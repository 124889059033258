@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mochiy+Pop+P+One&display=swap');
*{
    box-sizing: border-box;
}
/* Navbar */
.LogoHolder {
    max-width: 100%;
    cursor: pointer;
    display: flex;
    max-height: 80px;
}
.LogoimgHolder1{

    background-color: rgba(255, 255, 255, 0.6);
    margin-top: 1%;
    margin-bottom: 1%;
    transition: 1s ease-in-out;
    text-align: center;
}
.LogoimgHolder2{
    background-color: rgba(255, 255, 255, 0.6);
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: 5px;
    transition: 1s ease-in-out;
    text-align: center;
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 5%;
    width: 100%;
    border: rgba(0, 0, 0, 1);
    position: fixed;
    transition: 1s ease-in-out;
    z-index: 999;
}
.active{
    background-color: rgba(44,44,44, 0.9);
    z-index: 1;
}
.active .navigationMain a{
    color:white;
}
.active  .btnContact{
    background-color: #42692f;
}
.active .LogoimgHolder2{
    background-color: white;
    border-radius: 5px;
}
.active .LogoimgHolder1{
    background-color: white;
    border-radius: 5px;
}
.navigationMain {
    display: block;
    z-index: 0;
        margin: 0 auto; /* This sets the left and right margins to be equal, centering the element */
      
}
.navigationMobile {
    display: none;
    position: fixed;
    background: rgba(44, 44, 44, 0.9);
    width: 100%;
    margin-top: 120px ;
    z-index: 9;

    transition: 1s ease-in-out;
}
.navigationMobile ul{
    list-style-type: none;
}
.navigationMobile li{
font-size: 24px;
    padding: 5%;
}
.navigationMain ul{
    text-align: center;
    margin:0px auto;
    float: left;
    padding: 0px;
}
.navigationMain li{
    text-align: center;
    list-style-type:none;
    display: inline-block;
    padding: 0px 20px;
    font-size: 18px;

    font-weight: bold;
    text-decoration: none;
    overflow: hidden;
}
.navigationMain li:last-child{
    padding-right: 0px;
}
.navigationMain a{
    color: whitesmoke;
    transition: 0.3s ease;
    z-index: 0;
}
.navigationMain a:hover{
    color: #42692f;
    text-decoration: none;
}
.btnContact{
    background-color:rgba(0, 0, 0, 0.6);
    padding: 10px 25px;
    color: #FEFEFE;
    border: 1px solid white;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    font-size: 20px;
    margin-right: auto;
    margin-left: auto;
}
.btnContact a{
    background-color:rgba(0, 0, 0, 0);
    color: white;
    transition: all 0.3s ease 0s;
    text-align: center;
    padding: 20px 5px;
}
.btnContact:hover{
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid #1c202a ;
}
.btnContact:hover a{ 
    color: #1c202a;
}
.hamburgerMenu{
    display: none;
}
/* .dpdown ul{
    display: none;
}
.dpdown:hover ul{
    padding: 0;
    margin-left: -25px;
    border-radius: 20%;
    min-height: 100px;
    min-width: 100px;
    display: block;
    position: absolute;
    background-color: #88a99f;
}
.dpdown ul:hover{
    display: block;
}
.dpdown ul li:hover{
    min-width: 100px;
    background: #3b3b3b;
    font-weight: bold;
}
.dpdown li a{
    text-decoration: none;
    display: block;
}
.dpdown li{
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-top: 5px;
} */
@media only screen and (max-width: 768px) {
    .LogoHolder{
        margin: auto;
    }
    .navigationMain{
        display: none;
    }
    .btnContact{
        display: none;
    }
    .hamburgerMenu{
        display: block;
        font-size: 30px;
        float: right;
        width: auto;
        color: white;
    }
    .active{
        background-color: rgba(44,44,44, 0.9);
        z-index: 1;
    }
    
    .hamburgerMenu button{
        background-color:rgba(255  , 255, 255, 0.3);
        border: none;
        border-radius: 30%;
        transition: 0.3s ease;
    }
    .hamburgerMenu button:hover{
        background-color: rgba(0, 0, 0, 0.4);
        color:rgba(255  , 255, 255, 0.6);
    }
      .navigationMain li{
        color: white;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 17px;
        display: block;
      }
      
      .topnav a:hover {
        background-color: #ddd;
        color: black;
      }
  }
@media only screen and (max-width: 480px) {
   
  }


/* Home */
#Home {
    background: linear-gradient(to right, #00000000, #42692fcc), url('../TR/imgs/main-test.jpeg') no-repeat top center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 678px;
  }
.text h1,h5{
    text-align: center;
}
.text h1{
    font-family: 'Lobster', cursive;
    padding-top: 270px;
    font-size: 60px;
    color: #fcfcfc;
}
.text h1+h5{
    font-family: 'Encode Sans', sans-serif;
    font-weight: bolder;
    font-style: italic;
    margin-top: 25px;
}
#Boxes{
    padding-top: 5px;
    background-color: #ededed;
}
.boxHolder{
    margin: -50px 0px -75px 0px;
    justify-content: center;
}
.box{
   background-color: rgba(255  , 255, 255, 1);
   border: 1px solid rgba(0,0,0,0.4);
   box-shadow: 2px 20px 6px rgba(0,0,0,0.4);
   transition: 0.3s ease;
   border-radius: 20px;
   height: 100%;
   cursor:default;
}
.box li{
    list-style-type:none;
    justify-content: center;
}
.colHolder{
    padding-top: 20px;
}
.box:hover{
    transform: translate(0,-10px);
}
#icon{
    margin-top:10px;
    width: 50px;
}


@media only screen and (max-width: 768px){
#Home{
background: url(https://images.pexels.com/photos/1487834/pexels-photo-1487834.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);
background-attachment:local;
background-size: cover;
height: auto;
padding-bottom: 200px;
}
.text h1{
    padding-top: 200px;
    font-size: 40px;
}
.text h5{
    font-size: 16px;
}
.text h1+h5{
    margin-top: 30px;
}
#Boxes{
    background-color:rgb(245, 246, 247) ;
}
.box{
   width: auto;
   height: auto;
   box-shadow: 2px 20px 6px rgba(0,0,0,0.4);
}
#icon{
    width: 40px;
}
.colHolder h3{
    font-size: 20px;
    font-weight: bold;
}
.colHolder p{
    font-size: 14px;
}
}
/* About */
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

#About{
    background:#ededed;
    max-height: 100%;
    height: auto;
}
.aboutHolder{
    padding-top: 140px;
    text-align: center;
    margin-bottom: 20px;
}
.rowabout{
    margin: 0;
}
.textAbout{
    text-align: center;
    color: black;
}
.aboutHolder h3{
    font-family: 'Lobster', cursive;
    font-size: 40px;
    color: #474747;
}
#Tree{
    height: 100px;
}

@media only screen and (max-width: 1200px){
    #Tree{
        height: 80px;
    }
    .textAbout{
        font-size: 15px;
        margin-top: 20px;
    }
}
/* Production*/
#Production{
    height: 100%;
    background: url('./imgs/testbg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
   
}
.ProductionHolder{
    padding-top: 140px;
    margin-bottom: 0;
    overflow: hidden;
}
#Production h3{
    font-family: 'Lobster', cursive;
    font-size: 40px;
    text-align: center;
    margin-bottom: 20px;
}
.ProductionHolder p{
    color: black;
}
.ProductionRows{
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
}
.ProductionRows h5{
    color: black;
}
.ColumnP{
    background-color:rgba(255, 255, 255, 0.7);
    width: auto;
    border: 1px solid rgba(0,0,0,0.4);
    height: auto;
    cursor:default;
    bottom: 10px;
    box-shadow: 3px 20px 6px rgba(0,0,0,0.4);
    transition: .4s ease-in-out;
    border-radius: 30px;
    margin-left: 5px;
    margin-top:10px;
}
.ColumnP h4{
    font-size: 28px;
    margin-top: 30px;
    margin-bottom: 15px;
}
.ColumnP:hover{
    transform: translate(0,-10px);
    background-color:rgba(255, 255, 255, 0.9);
}

#Machines{
    height: 100%;
    background: url('./imgs/testbg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
#Machines h3{
    font-family: 'Lobster', cursive;
    font-size: 40px;
    text-align: center;
    margin-bottom: 20px;
}
#Machines p{
    text-align: center;
}
.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
    background-color: #42692f;
  }
.TablesContainer{
    padding-top: 140px;
    margin-bottom: 0;
    overflow: hidden;
}
table{
    background-color: whitesmoke;
    margin-top: 50px;
}
caption{
    background-color: whitesmoke;
    color: #444;
}
@media only screen and (max-width: 768px){
    #Production{
        height: 100%;
    }
    #Machines{
        height: auto;
    }
    #Production h3{
        font-size: 40px;
        text-align: center;
        margin-bottom: 20px;
    }
}
/* Products  */
#Products{
    background-color: #E8ECED;
    height: 100%;
    padding-bottom: 30px;
  }
#Products h3{
    color: black;
}
  .ProductsHolder{
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    color: #474747;
  }
  .ProductsHolder h3{
  font-family: 'Lobster', cursive;
  font-size: 40px;
  padding-right: 20px;
  padding-top: 140px;
  }
  #Products .row{
    padding:10px;
    margin-left: auto;
    margin-right: auto;
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 30px;
  }
  .grid-container img{
      width: 300px;
      height: 250px;
      border-radius: 30px;
  }
  .grid-item{
      text-align: center;
      font-size: 20px;
      font-family: 'Encode Sans', sans-serif;
  }
  #Contact{
    background-color: rgba(44,44,44, 1);
    color: #E8ECED;
    }
    #Contact h2{
        padding-top: 50px;
        font-family: 'Lobster', cursive;
    }
    #Contact h3{
        font-family: 'Lobster', cursive;
    }
    #Contact span,a{
        color: #E8ECED;
    }
    #Contact li{
        padding-top: 5px;
        text-align: center;
    }
    .left-col{
        margin-left:3%
    }
    .right-col{
        margin-right: 3%;
    }
  .contact-page-menu{
      list-style-type: none;
  }
  .footer{
    text-align: center;
    padding: 3px;
    background-color: #42692f;
    color: white;
    display: block;
  }