*{
  max-height: 100%;
  max-width: 100%;
}

html{
  scroll-behavior:smooth;
}
.Home{
  width: 100%;
  height: 100%;
}